<template>
  <div>
    
     <section class="mb-4 pt-3">
      <div class="container sm-px-0">
        <form class="" id="search-form" >
          <div class="row">
            <div class="col-xl-3">
              <div class="aiz-filter-sidebar collapse-sidebar-wrap sidebar-xl sidebar-right z-1035">
                <div class="overlay overlay-fixed dark c-pointer" data-toggle="class-toggle" data-target=".aiz-filter-sidebar" data-same=".filter-sidebar-thumb"></div>
                <div class="collapse-sidebar c-scrollbar-light text-left">
                  <!-- <div class="d-flex d-xl-none justify-content-between align-items-center pl-3 border-bottom">
                      <h3 class="h6 mb-0 fw-600">过滤器</h3>
                      <button type="button" class="btn btn-sm p-2 filter-sidebar-thumb" data-toggle="class-toggle" data-target=".aiz-filter-sidebar" >
                          <i class="las la-times la-2x"></i>
                      </button>
                  </div> -->
                  <div class="bg-white shadow-sm rounded mb-3">
                    <div class="fs-15 fw-600 p-3 border-bottom">
                      {{ $t('lei-bie') }} </div>
                    <div class="p-3">
                      <ul class="list-unstyled">
                        <!--展示全部分类-->
                        <li class="mb-2 ml-2" v-for="(item, i) in categoryList" :key="i" v-if="!categoryName" @click="toOtherCategory(item, 0)">
                          <a class="text-reset fs-14" >{{ item.Name }}</a>
                        </li>
                        <!--展示子类-->
                        <li class="mb-2" v-if="categoryName" @click="toPath('category')">
                          <a class="text-reset fs-14 fw-600">
                            <i class="las la-angle-left"></i>
                            {{ $t('suo-you-fen-lei') }} </a>
                        </li>
                        <li class="mb-2" v-if="categoryName" v-for="(item, i) in categoryParentData" :key="i" @click="toOtherCategory(item, i)">
                          <a class="text-reset fs-14 fw-600" >
                            <i class="las la-angle-left"></i>
                            {{item.name}}
                          </a>
                        </li>
                        <li class="mb-2" v-if="categoryName">
                          <a class="text-reset fs-14 fw-600">
                            <i class="las la-angle-left"></i>
                            {{categoryName}}
                          </a>
                        </li>
                        <li class="mb-2 pl-3" v-if="categoryName" v-for="(item, i) in childrenList" :key="`child-${i}`" @click="toOtherCategory(item, 2)">
                          <a class="text-reset fs-14 fw-600">
                            {{item.Name}}
                          </a>
                        </li>
                                
                      </ul>
                    </div>
                  </div>
                  <!-- <div class="bg-white shadow-sm rounded mb-3">
                    <div class="fs-15 fw-600 p-3 border-bottom">
                      价格范围
                    </div>
                    <div class="p-3">
                      <div class="aiz-range-slider">
                        <div id="input-slider-range" data-range-value-min="0" data-range-value-max="10000" >
                          <Slider v-model="form.price" range @change="changePrice" max="10000" active-color="#e62e04" bar-height="16"></Slider>
                        </div>

                        <div class="row mt-2">
                          <div class="col-6">
                            <span class="range-slider-value value-low fs-14 fw-600 opacity-70" >{{min_price}}</span>
                          </div>
                          <div class="col-6 text-right">
                            <span class="range-slider-value value-high fs-14 fw-600 opacity-70">{{max_price}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <!-- <div class="bg-white shadow-sm rounded mb-3" v-for="(item, i) in filterList" :key="i">
                    <div class="fs-15 fw-600 p-3 border-bottom">
                      过滤 {{item.name}}
                    </div>
                    <div class="p-3">
                      <div class="aiz-checkbox-list">
                        <label class="aiz-checkbox" v-for="(option, index) in item.optionList" :key="index">
                          <input type="checkbox"  :name="item.name" >
                            <span class="aiz-square-check"></span>
                            <span>{{ option.name }}</span>
                        </label>
                      </div>
                    </div>
                  </div> -->

                  <!-- <div class="bg-white shadow-sm rounded mb-3">
                    <div class="fs-15 fw-600 p-3 border-bottom">
                      过滤颜色
                    </div>
                    <div class="p-3">
                      <div class="aiz-radio-inline">
                        <label class="aiz-megabox pl-0 mr-2" v-for="(color, i) in colorList" :key="i">
                          <input type="radio"  name="color" :value="color">
                          <span class="aiz-megabox-elem rounded d-flex align-items-center justify-content-center p-1 mb-2">
                            <span class="size-30px d-inline-block rounded" :style="`background: ${color}`"></span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div> -->

                </div>
              </div>
            </div>

            <div class="col-xl-9">
              <ul class="breadcrumb bg-transparent p-0">
                <li class="breadcrumb-item opacity-50">
                  <a class="text-reset">{{ $t('shou-ye') }}</a>
                </li>
                <li class="breadcrumb-item fw-600  text-dark" @click="toPath('category')">
                  <a class="text-reset" >{{ $t('suo-you-lei-bie') }}</a>
                </li>
                <li class="text-dark fw-600 breadcrumb-item" v-if="categoryName" v-for="(item, i) in categoryParentData" :key="i" @click="toOtherCategory(item, i)"> 
                  <a class="text-reset" >"{{ item.name }}"</a>
                </li>
                <li class="text-dark fw-600 breadcrumb-item" v-if="categoryName"> 
                  <a class="text-reset" >"{{ categoryName }}"</a>
                </li>
              </ul>

              <div class="text-left">
                <div class="row gutters-5 flex-wrap align-items-center">
                  <div class="col-lg col-10">
                    <h1 class="h6 fw-600 text-body">
                      {{categoryName || '所有产品'}}
                    </h1>
                  </div>
                  <div class="col-2 col-lg-auto d-xl-none mb-lg-3 text-right">
                    <button type="button" class="btn btn-icon p-0" data-toggle="class-toggle" data-target=".aiz-filter-sidebar">
                      <i class="la la-filter la-2x"></i>
                    </button>
                  </div>
                  <!-- <div class="col-6 col-lg-auto mb-3 w-lg-200px" v-if="!form.company">
                      <label class="mb-0 opacity-50">{{ $t('pin-pai') }}</label>
                      <select class="form-control form-control-sm aiz-selectpicker" data-live-search="true" name="brand" onchange="filter()">
                        <option value="">{{ $t('suo-you-pin-pai') }}</option>
                        <option v-for="(option, i) in brandList" :value="option.value" :key="i">{{option.label}}</option>
                      </select>
                  </div> -->
                  <div class="col-6 col-lg-auto mb-3 w-lg-200px">
                      <label class="mb-0 opacity-50">{{ $t('pai-xu-fang-shi') }}</label>
                      <select class="form-control form-control-sm aiz-selectpicker" v-model="sort" name="sort_by" onchange="changeFilter()">
                          <option v-for="(option, i) in sortList" :value="option.value" :key="i">{{ option.label}}</option>
                      </select>
                  </div>
                </div>
              </div>

              <div class="row gutters-5 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-4 row-cols-md-3 row-cols-2">
                <div class="col" v-for="(item, i) in goodsList" :key="i">
                 <goodsList :goodsInfo="item"></goodsList>
                </div>
              </div>

              <div class="aiz-pagination aiz-pagination-center mt-4" v-if="page.total > 1">
                <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>

  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import goodsList from '@/components/goodsList'
import pageBar from '@/components/page'
import { Slider } from 'vant'
import {getGoodsList, getProductList, getCategoryList} from '@/api/index'
export default {
  components: {
    breadCrumb,
    Slider,
    goodsList,
    pageBar
  },
  data() {
    return {
      form: {
        price: [0, 0],
        company: ''
      },
      categoryParentData: [],
      colorList: ['#f00', '#ff0', '#f0f', '#0ff', '#00f', '#0f0'],
      min_price: 0,
      max_price: 0,
      keyword: '',
      filterList: [
        {
          name: 'Size',
          optionList: [
            { name: 'XXL'},
            { name: 'XL'},
            { name: 'L'},
            { name: 'M'},
            { name: 'S'},
            { name: 'XS'},
          ]
        },
        {
          name: 'Color',
          optionList: [
            { name: 'White'},
            { name: 'Black'},
            { name: 'Yellow'},
            { name: 'Red'},
            { name: 'Blue'},
            { name: 'Green'},
          ]
        },
      ],
      categoryName: '',
      categoryId: '',
      brandList: [],
      sort: "latest",
      sortList: [
        { label: this.$t("latest"), value: "latest"},
        { label: this.$t("oldest"), value: "oldest"},
        { label: this.$t("detail-sort-1"), value: "max"},
        { label: this.$t("detail-sort-2"), value: "min"},
      ],
      goodsList: [],
      page: {
        total: 1,
        page: 1,
        limit: 12
      },
      categoryList: [],
      childrenList: []
    }
  },
  watch: {
    '$route'(val) {
      this.changeRoute()
    },
    sort(val) {
      console.log("sort")
      console.log(val)
      this.page.page = 1
      this.init()
    },
    categoryListBak(val) {
      if (!this.categoryId && val && val.length > 0) {
        this.categoryList = this.categoryListBak.slice(0, 10)
      }
    }
  },
  computed: {
    categoryListBak() {
      return this.$store.state.categoryList
    },
  },
  mounted() {
    this.changeRoute()
  },
  methods: {
    changeRoute() {
      this.categoryName = this.$route.query.name
      this.categoryId = this.$route.query.id
      this.keyword = this.$route.query.keyword
      this.form.company = this.$route.query.company

      if (this.categoryId) {
        this.categoryParentData = JSON.parse(sessionStorage.getItem('categoryData'))
      } else {
        this.categoryList = this.categoryListBak.slice(0, 10)
      }
      this.init()
      this.initChild()
    },
    initChild() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      form.append('Pid', this.categoryId)
      getCategoryList(form).then(res => {
        this.childrenList = res.data.Items
      })
    },
    init() {
      console.log("getProductList")
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('sort', this.sort)
      form.append('categoryId', this.categoryId || '')
      form.append('title', this.keyword || '')
      console.log(this.categoryId)
      getProductList(form).then(res => {
        if (res.code == 0) {
          this.goodsList = res.data.Items
          this.page.total = res.data.Pagination.totalPage
        }
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    changePrice(val) {
      this.min_price = val[0]
      this.max_price = val[1]
    },
    changePage(page) {
      this.page.page = page 
      this.init()
    },
    toOtherCategory(data, i) {
      if (i == 1) {
        let categoryData = [
          {
            id: this.categoryParentData[0].id,
            name: this.categoryParentData[0].name,
          }
        ]
        sessionStorage.setItem('categoryData', JSON.stringify(categoryData))
      } else if (i == 2) {
         let categoryData = [
          {
            id: this.categoryParentData[0].id,
            name: this.categoryParentData[0].name,
          },
          {
            id: this.categoryId,
            name: this.categoryName,
          }
        ]
        sessionStorage.setItem('categoryData', JSON.stringify(categoryData))
      } else {
        sessionStorage.setItem('categoryData', '[]')
      }
      this.$router.push({
        name: 'categoryDetail',
        query: {
          name: data.name || data.Name,
          id: data.id || data.ID
        }
      })
    }
  }
}
</script>